import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import BookReview from "../views/BookReview.vue";
import Login from "../views/Login";
import NoteLegali from "../views/NoteLegali";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "filtered/:id",
        component: Home,
        name: "HomeFiltered"
      }
    ]
  },
  {
    path: "/bookReview/:id",
    name: "BookReview",
    component: BookReview,
    props: true
  },
  {
    path: "/bookEdit/:id?",
    name: "BookEdit",
    component: BookReview,
    props: true
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/note-legali",
    name: "NoteLegali",
    component: NoteLegali
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
