<template>
  <v-row>
    <v-col cols="12" md="8" lg="9">
      <book-list :books="books"></book-list>
      <div class="text-center mt-3">
        <v-pagination
          v-model="pagination.currentPage"
          :length="pagination.totalPages"
        ></v-pagination>
      </div>
    </v-col>
    <v-col md="4" lg="3" v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Generi</v-card-title>
            <v-card-text>
              <span v-for="genre in genres" :key="genre.id">
                <router-link
                  :to="{
                    name: 'HomeFiltered',
                    params: { id: genre.id, key: 'genres' }
                  }"
                  >{{ genre.name }}</router-link
                >
                |
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Autori</v-card-title>
            <v-card-text>
              <span v-for="author in authors" :key="author.id">
                <router-link
                  :to="{
                    name: 'HomeFiltered',
                    params: { id: author.id, key: 'author' }
                  }"
                  >{{ author.name }}</router-link
                >
                |
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Case editrici</v-card-title>
            <v-card-text>
              <span v-for="productor in productors" :key="productor.id">
                <router-link
                  :to="{
                    name: 'HomeFiltered',
                    params: { id: productor.id, key: 'productor' }
                  }"
                  >{{ productor.name }}</router-link
                >
                |
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import BookList from "../components/bookList/bookList.vue";

export default {
  name: "Home",
    metaInfo() {
      return {
        title: "Home",
        meta: [{
          name: 'description',
          content: 'Portale che raccoglie le recensioni delle nostre letture.'
        }],
      };
    },
  components: { BookList },
  methods: {
    getBooks() {
      this.$store.dispatch("getBooks", {field: 'createdAt', sort: -1, page: (this.pagination.currentPage - 1) * 2}).then((response) => {
        this.pagination.totalPages = this._.round(response.total / response.limit);
      });
    }
  },
  data() {
    return {
      pagination: {
        totalPages: 10,
        currentPage: 1
      }
    };
  },
  watch: {
    "pagination.currentPage": function(){
      this.getBooks();
    }
  },
  computed: {
    books() {
      const { id, key } = this.$route.params;
      return this.$store.getters.books(book => {
        if (id) {
          if (book[key]) {
            if (this._.isArray(book[key])) {
              return book[key].find(g => g.id === id);
            } else {
              return book[key].id === id;
            }
          }
        } else {
          return true;
        }
      });
    },
    ...mapState(["genres", "authors", "productors"])
  },
  mounted: function() {
    this.$store.commit("setBtnAction", {
      icon: "mdi-plus",
      action: "insert",
      visible: true
    });
    
    this.getBooks();
    this.$store.dispatch("getGenres");
    this.$store.dispatch("getProductors");
    this.$store.dispatch("getAuthors");
  }
};
</script>
