import { render, staticRenderFns } from "./fileBase64.vue?vue&type=template&id=5d9e5b0a&"
import script from "./fileBase64.vue?vue&type=script&lang=js&"
export * from "./fileBase64.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VFileInput,VImg,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/marco-andreoli/dev/IlMondoDelLibro-Client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d9e5b0a')) {
      api.createRecord('5d9e5b0a', component.options)
    } else {
      api.reload('5d9e5b0a', component.options)
    }
    module.hot.accept("./fileBase64.vue?vue&type=template&id=5d9e5b0a&", function () {
      api.rerender('5d9e5b0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/fileBase64.vue"
export default component.exports