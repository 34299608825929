import { Ability } from "@casl/ability";
export default store => {
  const defaultRoles = [
    {
      action: "read",
      subject: "book",
      conditions: { publishedStatus: 1 }
    }
  ];
  const ability = new Ability(defaultRoles, {
    subjectName(subject) {
      return !subject || typeof subject === "string" ? subject : subject.type;
    }
  });

  store.registerModule("auth", {
    namespaced: true,
    state: {
      ability: ability,
      user: {
        username: "Guest"
      },
      roles: defaultRoles,
      token: ""
    },
    getters: {
      ability(state) {
        return state.ability;
      },
      isLogged(state) {
        return !!state.token;
      }
    },
    mutations: {
      createSession(state, { user, token }) {
        state.user = user;
        state.token = token;
      },
      destroySession(state) {
        state.token = null;
        state.user = { username: "Guest" };
      }
    },
    actions: {
      getUsers() {
        this._vm.$axiosAuth.get("users").then({});
      },
      login({ commit }, user) {
        let sessionInfo = {
          token: ""
        };

        if (user.strategy === "google") {
          window.location.href = `${process.env.VUE_APP_AUTH_API}/oauth/google`;
        } else {
          this._vm.$axiosAuth
            .post("/authentication", user)
            .then(response => {
              this._vm.$log.debug(response);
              sessionInfo.user = response.data.user;
              sessionInfo.token = response.data.accessToken;
              commit("createSession", sessionInfo);
            })
            .catch(error => {
              this._vm.$log.error(error);
            });
        }
      },
      logout({ commit }) {
        commit("destroySession");
      }
    }
  });

  return store.subscribe(({ type, payload }) => {
    if (type === "auth/createSession") {
      ability.update(payload.user.role.abilities);

      sessionStorage.setItem("SessionToken", payload.token);
    }

    if (type === "auth/destroySession") {
      ability.update(defaultRoles);
      sessionStorage.removeItem("SessionToken");
    }
  });
};
