<template>
  <v-card class="pa-2">
    <v-img :src="book.homeImage" aspect-ratio="2" contain height="150"></v-img>
    <v-card-title
      >{{ book.title }}<br />({{
        book.author ? book.author.name : ""
      }})</v-card-title
    >
    <v-card-subtitle
      ><span v-for="(genre, index) in book.genres" :key="genre.id">
        {{ genre.name }}<span v-if="index < book.genres.length - 1">, </span>
      </span></v-card-subtitle
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary"
        :to="{ name: 'BookReview', params: { id: book.urlId } }"
        >Dettaglio</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BookListItem",
  props: ["book"],
  components: {}
};
</script>
