var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            _vm.action == "edit" || _vm.action == "insert"
              ? "book-edit"
              : "book-detail",
            _vm._b({ tag: "component" }, "component", { book: _vm.book }, false)
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }