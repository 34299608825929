import axios from "axios";
import store from "./../../store";

export default {
  installAxios (Vue) {
    Vue.prototype.$axios = axios.create({
      baseURL: process.env.VUE_APP_API
    });

    Vue.prototype.$axiosAuth = axios.create({
      baseURL: process.env.VUE_APP_AUTH_API || process.env.VUE_APP_API
    });

    Vue.prototype.$axios.interceptors.response.use(
      response => {
        Vue.prototype.$log.debug("RESPONSE", response.config.url, response);
        //debugger
        store.commit("setHttpState", false);
        return response.data;
      },
      error => {
        Vue.prototype.$log.error(error);
      }
    );

    Vue.prototype.$axios.interceptors.request.use(
      request => {
        store.commit("setHttpState", true);

        if (sessionStorage.getItem("SessionToken")) {
          request.headers = Object.assign({}, request.headers, {
            Authorization: sessionStorage.getItem("SessionToken")
          });
        }

        Vue.prototype.$log.debug("REQUEST", request.url, request);
        return request;
      },
      error => {
        Vue.prototype.$log.error(error);
      }
    );
  }
};
