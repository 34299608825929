<template>
  <v-textarea :label="label" :value="value" readonly rows="3" no-resize>
    <template slot="append">
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-pencil</v-icon>
        </template>

        <v-card>
          <tiptap-vuetify
            :extensions="extensions"
            :value="value"
            v-on:input="updateValue"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-textarea>
</template>

<script>
import {
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  props: ["value", "label"],
  data: () => ({
    dialog: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ]
  }),
  methods: {
    updateValue(value) {
      value = value.replace(/<h([2-3])>/i, '<h$1 class="primary--text">');
      this.$emit("input", value);
    }
  }
};
</script>
