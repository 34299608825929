import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { AuthPlugin } from "./plugins/maUtils";
import { TiptapVuetify, TiptapVuetifyPlugin } from "tiptap-vuetify";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;
Vue.use(AuthPlugin);
Vue.use(VueLodash, { name: "custom", lodash: lodash });

Vue.component("TiptapVuetify", TiptapVuetify);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi"
});

var googleAnalyticsEnabled =
  process.env.VUE_APP_ENABLE_GOOGLE_ANALYTICS === "true";
Vue.$log.debug(
  "google analytics status",
  googleAnalyticsEnabled ? "enabled" : "disabled"
);
if (googleAnalyticsEnabled) {
  Vue.use(VueAnalytics, {
    id: "UA-5999507-3",
    router
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
