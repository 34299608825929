var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            absolute: "",
            color: "#6A76AB",
            dark: "",
            "shrink-on-scroll": "",
            prominent: "",
            src: "/img/books-6.jpg"
          },
          scopedSlots: _vm._u([
            {
              key: "img",
              fn: function(ref) {
                var props = ref.props
                return [
                  _c(
                    "v-img",
                    _vm._b(
                      {
                        attrs: {
                          gradient:
                            "to top right, rgba(255,255,255,.4), rgba(25,32,72,.4)"
                        }
                      },
                      "v-img",
                      props,
                      false
                    )
                  )
                ]
              }
            },
            {
              key: "extension",
              fn: function() {
                return [
                  _c(
                    "v-tabs",
                    { attrs: { "align-with-title": "" } },
                    [
                      _c(
                        "v-tab",
                        { attrs: { to: { name: "Home" } } },
                        [_c("v-icon", [_vm._v("mdi-home")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.btnAction.visible
                    ? _c(
                        "span",
                        [
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.btnAction.action.params.action,
                                a: "book"
                              }
                            },
                            [
                              _c(
                                "v-fab-transition",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "pink",
                                        fab: "",
                                        dark: "",
                                        small: "",
                                        absolute: "",
                                        bottom: "",
                                        right: "",
                                        to: _vm.btnAction.action
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.btnAction.icon))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-toolbar-title",
            {
              staticClass: "primary--text",
              class: {
                "text-h1 pl-16 ml-16": _vm.$vuetify.breakpoint.name != "xs"
              }
            },
            [
              _c("span", { staticClass: "custom-title" }, [
                _vm._v("Il Mondo del Libro")
              ])
            ]
          ),
          _c("v-spacer"),
          !_vm.isLogged
            ? _c(
                "v-btn",
                { staticClass: "primary", attrs: { to: { name: "Login" } } },
                [_vm._v("Login")]
              )
            : _vm._e(),
          _vm.isLogged
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g({ attrs: { icon: "" } }, on),
                              [
                                _c("v-avatar", { attrs: { color: "brown" } }, [
                                  !_vm.showInitials
                                    ? _c("img", {
                                        attrs: { src: _vm.user.profilePicture },
                                        on: {
                                          error: function($event) {
                                            _vm.showInitials = true
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.showInitials
                                    ? _c("span", [_vm._v("M")])
                                    : _vm._e()
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2857245039
                  )
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-list-item-avatar", [
                                _c("img", {
                                  attrs: { src: _vm.user.profilePicture }
                                })
                              ]),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.user.name))
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(_vm.user.username))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            { on: { click: _vm.logout } },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("mdi-logout")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Logout")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticStyle: { "margin-top": "230px" } },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _c(
        "footer",
        [
          _c(
            "cookie-law",
            {
              attrs: {
                theme: "dark-lime",
                buttonText: "OK",
                buttonLink: "note-legali",
                buttonLinkText: "Maggiori Informazioni",
                buttonLinkNewTab: true
              }
            },
            [
              _c("div", { attrs: { slot: "message" }, slot: "message" }, [
                _vm._v(
                  " Questo sito utilizza i cookie per garantire una navigazione ottimale. Proseguendo la navigazione accetti le nostre condizioni d'uso. Fai clic su ok per chiudere l'avviso, oppure leggi l'informativa completa. "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.httpInPending } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }