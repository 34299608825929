var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c("v-file-input", {
            attrs: { accept: _vm.typeAccepted, label: _vm.label },
            on: { change: _vm.convertFile }
          })
        ],
        1
      ),
      _vm.showPreview
        ? _c(
            "v-col",
            [
              _c("v-img", {
                attrs: {
                  src: _vm.value,
                  "aspect-ratio": "2",
                  contain: "",
                  height: "150"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }