<template>
  <v-row>
    <v-col cols="12">
      <h3>Cookie tecnici</h3>
      <p>
        Vengono utilizzati nella misura strettamente necessaria per garantire
        un’agevole navigazione da parte degli utenti, consentendo al tempo
        stesso di memorizzare alcuni dati come le credenziali di accesso. Non
        vengono utilizzati per altri scopi. I cookie di questa categoria, ed
        utilizzati nel sito, comprendono:
      </p>
      <ul>
        <li>
          cookie di sessione: Utilizzati solo per gli utenti registrati. La
          registrazione del sito è limitata ai soli fondatori del blog
        </li>
        <li>
          cookie analytics: utilizzati per raccogliere informazioni sul numero
          degli utenti e su come visitano il sito. Tutte le informazioni sono
          raccolte in forma anonima.
        </li>
      </ul>
      <br />
      <h3>Cookie utilizzati nel blog</h3>
      <ul>
        <li>
          Cookie di sessione: I cookie di sessione si attiveranno solo e
          soltanto in caso di registrazione e, quindi, di login al blog. Tale
          servizio è permesso ai soli fondatori del sito:
          <ul>
            <li>Andreoli Marco</li>
            <lI>Vaccari Stefania</lI>
          </ul>
        </li>
        <li>
          Google Analytics: Utilizziamo Analytics per capire come viene
          utilizzato il sito di 4hse, cercando di migliorare di volta in volta
          l’esperienza utente. I dati vengono raccolti in forma anonima. Per
          ulteriori dettagli, potete consultare:
          <a target="_blank" href="https://policies.google.com/"
            >Note legali Google</a
          >
        </li>
        <li>
          Amazon Affiliate: I link amazon vengono creati tramite il programma di
          affiliazione di Amazon stessa. Potete avere ultiriori dettagli tramite
          il seguente link:<a
            target="_blank"
            href="https://www.amazon.it/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ"
            >Note legali Amazon</a
          >
        </li>
      </ul>
      <br />
      <h3>Gestione dei cookie</h3>
      <p>
        Per l’attivazione o la disattivazione dei cookie, oltre agli strumenti
        previsti dai diversi browser, invitiamo l’utente che desiderasse
        approfondire e intervenire sulla configurazione, a visitare il sito:<a
          target="_blank"
          href="https://www.youronlinechoices.com/"
          >https://www.youronlinechoices.com/</a
        >
        <br />
        Per completezza riportiamo, a titolo esemplificativo, i link a cui
        accedere per attivare o disattivare i cookie di alcuni dei principali
        browser:
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=it"
            >Google Chrome</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it"
            >Mozilla Firefox</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
            >Internet Explorer</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.microsoft.com/en-us/search?query=edge%20clear%20cookies%20and%20cache"
            >Microsoft Edge</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://help.opera.com/en/latest/web-preferences/"
            >Opera</a
          >
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NoteLegali"
};
</script>
