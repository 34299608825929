<template>
  <v-row>
    <v-col cols="12" md="6" lg="4" v-for="book in books" :key="book.id">
      <book-list-item :book="book"></book-list-item>
    </v-col>
  </v-row>
</template>

<script>
import bookListItem from "./bookListItem";

export default {
  name: "BookList",
  props: ["books"],
  components: { bookListItem }
};
</script>
