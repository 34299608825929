<template>
  <v-card shaped>
    <v-card-title class="primary">
      <div style="width: 100%">
        <span
          class="white--text"
          :class="{ 'text-h4': $vuetify.breakpoint.name != 'xs' }"
        >
          {{ book.title }}<br v-if="$vuetify.breakpoint.name == 'xs'" />
          ({{ _.get(book, "author.name") }})
        </span>
        <span
          style="float: right; cursor: pointer"
          :style="[shareStyle]"
          @click="share"
        >
          <v-icon color="white">mdi-share-variant</v-icon>
        </span>
      </div>
    </v-card-title>
    <v-row class="pl-2">
      <v-col cols="12" sm="4">
        <v-img :src="book.coverImage"></v-img>
      </v-col>
      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="5" sm="3" class="font-weight-black">Autore: </v-col>
          <v-col cols="7" sm="9">
            <a :to="{ name: 'authorBiografy', params: { id: book.id } }">
              {{ _.get(book, "author.name") }}
            </a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" sm="3" class="font-weight-black">Genere: </v-col>
          <v-col cols="7" sm="9">
            <span v-for="(genre, index) in book.genres" :key="genre.id">
              {{ genre.name
              }}<span v-if="index < book.genres.length - 1">, </span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" sm="3" class="font-weight-black">
            Casa Editrice:
          </v-col>
          <v-col cols="7" sm="9">
            {{ _.get(book, "productor.name") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" sm="3" class="font-weight-black">Link Amazon: </v-col>
          <v-col cols="7" sm="9">
            <a target="_blank" :href="book.amazonLink">
              {{ book.amazonLink }}
            </a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="font-weight-black">Voto:</v-col>
          <v-col cols="9">
            <v-rating
              v-model="ratingAverage"
              background-color="orange lighten-3"
              color="orange"
              half-increments
              readonly
            ></v-rating>
          </v-col>
        </v-row>
        <v-row v-if="book.citation">
          <v-row class="review">
            <v-col cols="12">
              <div class="blockquote-citation pr-2">
                <div v-html="book.citation" class="citation"></div>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <blockquote class="blockquote ml-16 pr-2">
          <h2 class="primary--text">Trama</h2>
          <div v-html="book.plot"></div>
        </blockquote>
      </v-col>
    </v-row>
    <v-row v-for="review in book.reviews" :key="review.author">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="8" class="px-8">
            <h2 class="primary--text">Recensione ({{ review.user.name }})</h2>
          </v-col>
          <v-col cols="12" sm="4" class="pt-0">
            <v-rating
              v-model="review.rating"
              background-color="orange lighten-3"
              color="orange"
              half-increments
              readonly
            ></v-rating>
          </v-col>
        </v-row>
        <v-row class="review">
          <v-col cols="12" class="ml-3">
            <div v-html="review.review"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss" scoped>
@import "./../../styles/_variables.scss";

.review {
  margin-right: 10px;
}

.blockquote {
  border-left: 3px solid;
  border-left-color: $primary-color;
}

.blockquote-citation::before {
  margin-top: -20px;
  content: open-quote;
  font-size: 56px;
  font-style: italic;
  font-family: fontAwesome;
  position: absolute;
}

.blockquote-citation {
  quotes: "\201C";
  color: #009688;
}

.citation {
  margin-left: 40px;
  color: #000000;
}
</style>

<script>
import shrotApi from "./../../store/apisStore";

export default {
  name: "BookDetail",
  props: ["book"],
  computed: {
    shareStyle: function() {
      var toRet = {
        "margin-top": "0"
      };

      if (this.$vuetify.breakpoint.name == "xs") {
        toRet["margin-top"] = "-20px";
      }

      return toRet;
    },
    ratingAverage: function() {
      var average = 0;

      if (this.book.reviews && this.book.reviews.length > 0) {
        const reviews = this.book.reviews;

        average =
          this.book.reviews.reduce((accumulator, currentValue) => {
            return (accumulator.rating += currentValue.rating);
          }).rating / reviews.length;
      }
      return average;
    }
  },
  methods: {
    share() {
      shrotApi.getShortUrl(window.location.href).then(response => {
        if (navigator.share) {
          navigator
            .share({
              title: "ilmondodellibro.eu",
              text: "",
              url: "https://" + response.data.shortUrl
            })
            .then(() => console.log("Successful share"))
            .catch(error => console.log("Error sharing", error));
        }
      });
    }
  },
  mounted() {
    this.$store.commit("setBtnAction", {
      icon: "mdi-pencil",
      id: this.book.id,
      action: "edit",
      visible: true
    });
  }
};
</script>
