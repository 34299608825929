var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-textarea",
    {
      attrs: {
        label: _vm.label,
        value: _vm.value,
        readonly: "",
        rows: "3",
        "no-resize": ""
      }
    },
    [
      _c(
        "template",
        { slot: "append" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c("v-icon", _vm._g({}, on), [_vm._v("mdi-pencil")])
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("tiptap-vuetify", {
                    attrs: { extensions: _vm.extensions, value: _vm.value },
                    on: { input: _vm.updateValue }
                  }),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }