import Vue from "vue";
import Vuex from "vuex";

import abilityPlugin from "../plugins/maUtils/authorization/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ability: null,
    title: "",
    description: "Prova",
    books: [],
    book: {},
    authors: [],
    productors: [],
    genres: [],
    httpInPending: false,
    btnAction: {
      icon: "mdi-plus",
      visible: true,
      action: {
        name: "BookEdit",
        params: {
          action: "insert"
        }
      }
    }
  },
  mutations: {
    setGenres(state, genres) {
      state.genres = genres;
    },
    setBooks(state, books) {
      state.books = books.map(book => {
        return Object.assign({}, book, { type: "book" });
      });
    },
    setBtnAction(state, { icon, id, action, visible }) {
      state.btnAction.icon = icon;
      state.btnAction.action.params.id = id;
      state.btnAction.action.params.action = action;
      state.btnAction.visible = visible || false;
    },
    setHttpState(state, httpState){
      state.httpInPending = httpState
    },
    addBook(state, book) {
      if (
        state.books.findIndex(b => {
          return b.id === book.id;
        }) > -1
      ) {
        state.books[
          state.books.findIndex(b => {
            return b.id === book.id;
          })
        ] = book;
      } else {
        state.books.push(book);
      }
    },
    setBook(state, book) {
      state.book = book;
    },
    setBookId(state, {id}){
      state.book.id = id;
    },
    setAuthors(state, authors) {
      state.authors = authors;
    },
    setProductors(state, productors) {
      state.productors = productors;
    },
    setMetaTags(state, metaTags) {
      state.title = metaTags.title;
      state.description = metaTags.description;
    }
  },
  actions: {
    getGenres({ commit }) {
      return this._vm.$axios.get("genres").then(response => {
        commit("setGenres", response.data);
        return response;
      });
    },
    getBooks({ commit }, sortOrder) {
      return this._vm.$axios.get(`books?$sort[${sortOrder.field || 'createAt'}]=${sortOrder.sort || 1}&$limit=4&$skip=${sortOrder.page}`).then(response => {
        commit("setBooks", response.data);
        return response;
      });
    },

    getBook({ commit }, { id }) {
      return this._vm.$axios.get(`books/${id}`).then(book => {
        commit("setBook", book);
        return book;
      });
    },
    getAuthors({ commit }) {
      return this._vm.$axios.get(`authors`).then(response => {
        commit("setAuthors", response.data);
        return response;
      });
    },
    getProductors({ commit }) {
      return this._vm.$axios.get(`productors`).then(response => {
        commit("setProductors", response.data);
        return response;
      });
    },
    saveBook({ commit }, book) {
      let toSave = Object.assign({}, book);

      if (!book.id) {
        return this._vm.$axios.post("books", toSave).then(response => {
          commit("addBook", response);
          commit("setBookId", response);
          return response;
        });
      } else {
        return this._vm.$axios
          .put(`books/${book.id}`, toSave)
          .then(response => {
            commit("addBook", response);
            commit("setBookId", response);
            return response;
          });
      }
    }
  },
  getters: {
    booksAbilities(state, getters) {
      return state.books.filter(book => {
        return getters["auth/ability"].can("read", book);
      });
    },
    books: (state, getters) => filter => {
      return getters.booksAbilities.filter(filter);
    }
  },
  modules: {},
  plugins: [abilityPlugin]
});
