<template>
  <div class="d-flex justify-center mt-3">
    <v-flex md4>
      <v-card class="pa-4">
        <p class="text-h4 text-center">Login</p>
        <v-form>
          <v-text-field
            label="Username"
            prepend-icon="mdi-account"
            v-model="user.email"
          ></v-text-field>
          <v-text-field
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="!showPsw ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="user.password"
            :type="!showPsw ? 'password' : 'text'"
            @click:append="showPsw = !showPsw"
          ></v-text-field>
          <v-btn class="primary" large block @click="login('local')"
            >Login</v-btn
          >
          <p class="text-body-1 text-center mt-3">Or</p>
          <v-btn large block @click="login('google')">Google Login</v-btn>
        </v-form>
      </v-card>
    </v-flex>
  </div>
</template>
<script>
export default {
  name: "Login",
  data: () => ({
    showPsw: false,
    user: {
      strategy: "local",
      email: "",
      password: ""
    }
  }),
  methods: {
    login(strategy) {
      this.user.strategy = strategy;

      this.$store.dispatch("auth/login", this.user);
    }
  }
};
</script>
