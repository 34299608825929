<template>
  <v-row>
    <v-col cols="12">
      <component
        :is="
          action == 'edit' || action == 'insert' ? 'book-edit' : 'book-detail'
        "
        v-bind="{ book: book }"
      ></component>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import BookDetail from "./../components/bookDetail/bookDetail";
import BookEdit from "./../components/bookDetail/bookEdit";

export default {
  name: "BookReview",
  metaInfo() {
      return {
        title: this.book.title + " - " + this.book.author.name,
        meta: [{
            name: "description",
            content: "recensione " + this.book.title + " di " + this.book.author.name
        }]
      };
    },
  props: ["action"],
  components: { BookDetail, BookEdit },
  mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch("getBook", this.$route.params);
    } else {
      this.$store.commit("setBook", { reviews: [] });
    }
  },
  computed: {
    ...mapState(["book"])
  },
  methods: {
    truncateSynopsis() {
      let toRet = "";

      // toRet = this.book.plot.substring(0, 165);

      // if (this.book.plot.length > 165) {
      //   toRet += "...";
      // }

      return toRet;
    }
  }
};
</script>
