import axios from "./axios";
import logger from "./logger";

export const AuthPlugin = {
  install(Vue) {
    logger.installLogger(Vue);
    axios.installAxios(Vue);

    Vue.mixin({
      beforeRouteEnter: function(to, from, next) {
        next(vm => {
          if (to.hash) {
            const splitted = to.hash.split("=");
            const key = splitted[0].replace("#", "");
            const value = decodeURIComponent(splitted[1]);

            vm.$log.debug(key, value);

            if (key === "access_token") {
              const user = {
                strategy: "jwt",
                accessToken: splitted[1]
              };
              vm.$store.dispatch("auth/login", user);
              next("/");
            }

            if (key === "error") {
              vm.$log.error(decodeURIComponent(to.hash.split("=")[1]));
              next("/login");
            }
          }
        });
      }
    });
  }
};
