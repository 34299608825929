var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { shaped: "" } },
    [
      _c("v-card-title", { staticClass: "primary" }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c(
            "span",
            {
              staticClass: "white--text",
              class: { "text-h4": _vm.$vuetify.breakpoint.name != "xs" }
            },
            [
              _vm._v(" " + _vm._s(_vm.book.title)),
              _vm.$vuetify.breakpoint.name == "xs" ? _c("br") : _vm._e(),
              _vm._v(" (" + _vm._s(_vm._.get(_vm.book, "author.name")) + ") ")
            ]
          ),
          _c(
            "span",
            {
              staticStyle: { float: "right", cursor: "pointer" },
              style: [_vm.shareStyle],
              on: { click: _vm.share }
            },
            [
              _c("v-icon", { attrs: { color: "white" } }, [
                _vm._v("mdi-share-variant")
              ])
            ],
            1
          )
        ])
      ]),
      _c(
        "v-row",
        { staticClass: "pl-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [_c("v-img", { attrs: { src: _vm.book.coverImage } })],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "font-weight-black",
                      attrs: { cols: "5", sm: "3" }
                    },
                    [_vm._v("Autore: ")]
                  ),
                  _c("v-col", { attrs: { cols: "7", sm: "9" } }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          to: {
                            name: "authorBiografy",
                            params: { id: _vm.book.id }
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm._.get(_vm.book, "author.name")) + " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "font-weight-black",
                      attrs: { cols: "5", sm: "3" }
                    },
                    [_vm._v("Genere: ")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7", sm: "9" } },
                    _vm._l(_vm.book.genres, function(genre, index) {
                      return _c("span", { key: genre.id }, [
                        _vm._v(" " + _vm._s(genre.name)),
                        index < _vm.book.genres.length - 1
                          ? _c("span", [_vm._v(", ")])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "font-weight-black",
                      attrs: { cols: "5", sm: "3" }
                    },
                    [_vm._v(" Casa Editrice: ")]
                  ),
                  _c("v-col", { attrs: { cols: "7", sm: "9" } }, [
                    _vm._v(
                      " " + _vm._s(_vm._.get(_vm.book, "productor.name")) + " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "font-weight-black",
                      attrs: { cols: "5", sm: "3" }
                    },
                    [_vm._v("Link Amazon: ")]
                  ),
                  _c("v-col", { attrs: { cols: "7", sm: "9" } }, [
                    _c(
                      "a",
                      {
                        attrs: { target: "_blank", href: _vm.book.amazonLink }
                      },
                      [_vm._v(" " + _vm._s(_vm.book.amazonLink) + " ")]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "font-weight-black", attrs: { cols: "3" } },
                    [_vm._v("Voto:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c("v-rating", {
                        attrs: {
                          "background-color": "orange lighten-3",
                          color: "orange",
                          "half-increments": "",
                          readonly: ""
                        },
                        model: {
                          value: _vm.ratingAverage,
                          callback: function($$v) {
                            _vm.ratingAverage = $$v
                          },
                          expression: "ratingAverage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.book.citation
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-row",
                        { staticClass: "review" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "blockquote-citation pr-2" },
                              [
                                _c("div", {
                                  staticClass: "citation",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.book.citation)
                                  }
                                })
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("blockquote", { staticClass: "blockquote ml-16 pr-2" }, [
              _c("h2", { staticClass: "primary--text" }, [_vm._v("Trama")]),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.book.plot) } })
            ])
          ])
        ],
        1
      ),
      _vm._l(_vm.book.reviews, function(review) {
        return _c(
          "v-row",
          { key: review.author },
          [
            _c(
              "v-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "px-8", attrs: { cols: "12", sm: "8" } },
                      [
                        _c("h2", { staticClass: "primary--text" }, [
                          _vm._v(
                            "Recensione (" + _vm._s(review.user.name) + ")"
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pt-0", attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-rating", {
                          attrs: {
                            "background-color": "orange lighten-3",
                            color: "orange",
                            "half-increments": "",
                            readonly: ""
                          },
                          model: {
                            value: review.rating,
                            callback: function($$v) {
                              _vm.$set(review, "rating", $$v)
                            },
                            expression: "review.rating"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "review" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "ml-3", attrs: { cols: "12" } },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(review.review) }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }