var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._l(_vm.books, function(book) {
      return _c(
        "v-col",
        { key: book.id, attrs: { cols: "12", md: "6", lg: "4" } },
        [_c("book-list-item", { attrs: { book: book } })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }