<template>
  <v-row>
    <v-col
      ><v-file-input
        :accept="typeAccepted"
        :label="label"
        v-on:change="convertFile"
      ></v-file-input
    ></v-col>
    <v-col v-if="showPreview"
      ><v-img :src="value" aspect-ratio="2" contain height="150"></v-img
    ></v-col>
  </v-row>
</template>
<script>
const mapFileTypes = {
  image: "image/*",
  all: "*",
  video: "video/*",
  audio: "audio/*"
};

function getFileAccept(type) {
  var toRet = mapFileTypes[type];

  if (!toRet) {
    toRet = type;
  }

  return toRet;
}

export default {
  props: {
    value: {
      type: String
    },
    label: {
      type: String
    },
    showPreview: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: "all"
    }
  },
  computed: {
    typeAccepted() {
      return getFileAccept(this.accept);
    }
  },
  methods: {
    convertFile(file) {
      const render = new FileReader();

      render.onload = result => {
        this.$emit("input", result.target.result);
      };

      render.readAsDataURL(file);
    }
  }
};
</script>
