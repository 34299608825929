import Vue from "vue";
import Vuetify from "vuetify/lib";
import Meta from "vue-meta";
import { abilitiesPlugin, Can } from "@casl/vue";
import store from "./../store";

Vue.use(Vuetify);
Vue.use(Meta);
Vue.use(abilitiesPlugin, store.getters["auth/ability"]);

Vue.component("Can", Can);

if (process.env.NODE_ENV == "development") {
  window.ability = store.getters["auth/ability"];
}

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#009688",
        secondary: "#8bc34a",
        accent: "#ffc107",
        error: "#f44336",
        warning: "#ff9800",
        info: "#00bcd4",
        success: "#4caf50"
      }
    }
  }
});
