var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center mt-3" },
    [
      _c(
        "v-flex",
        { attrs: { md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "pa-4" },
            [
              _c("p", { staticClass: "text-h4 text-center" }, [
                _vm._v("Nuova recensione")
              ]),
              _c(
                "v-form",
                [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("v-switch", {
                        attrs: { label: "Pubblicato" },
                        model: {
                          value: _vm.book.publishedStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.book, "publishedStatus", $$v)
                          },
                          expression: "book.publishedStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: { label: "Titolo", "prepend-icon": "mdi-account" },
                    model: {
                      value: _vm.book.title,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "title", $$v)
                      },
                      expression: "book.title"
                    }
                  }),
                  _c("v-combobox", {
                    attrs: {
                      label: "Autore",
                      items: _vm.authors,
                      "item-text": "name",
                      "item-value": "id",
                      "return-object": "",
                      "prepend-icon": "mdi-account"
                    },
                    model: {
                      value: _vm.book.author,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "author", $$v)
                      },
                      expression: "book.author"
                    }
                  }),
                  _c("v-combobox", {
                    attrs: {
                      label: "Casa Editrice",
                      items: _vm.productors,
                      "item-text": "name",
                      "item-value": "id",
                      "return-object": "",
                      "prepend-icon": "mdi-account"
                    },
                    model: {
                      value: _vm.book.productor,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "productor", $$v)
                      },
                      expression: "book.productor"
                    }
                  }),
                  _c("v-combobox", {
                    attrs: {
                      label: "Generi",
                      items: _vm.genres,
                      "item-text": "name",
                      "item-value": "id",
                      "prepend-icon": "mdi-account",
                      "return-object": "",
                      multiple: ""
                    },
                    model: {
                      value: _vm.book.genres,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "genres", $$v)
                      },
                      expression: "book.genres"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Link Amazon",
                      "prepend-icon": "mdi-account"
                    },
                    model: {
                      value: _vm.book.amazonLink,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "amazonLink", $$v)
                      },
                      expression: "book.amazonLink"
                    }
                  }),
                  _c("editor", {
                    attrs: { label: "Citazione" },
                    model: {
                      value: _vm.book.citation,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "citation", $$v)
                      },
                      expression: "book.citation"
                    }
                  }),
                  _c("file-base-64", {
                    attrs: { accept: "image", label: "Copertina del libro" },
                    model: {
                      value: _vm.book.coverImage,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "coverImage", $$v)
                      },
                      expression: "book.coverImage"
                    }
                  }),
                  _c("file-base-64", {
                    attrs: { accept: "image", label: "Copertina Home" },
                    model: {
                      value: _vm.book.homeImage,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "homeImage", $$v)
                      },
                      expression: "book.homeImage"
                    }
                  }),
                  _c("editor", {
                    attrs: { label: "Trama" },
                    model: {
                      value: _vm.book.plot,
                      callback: function($$v) {
                        _vm.$set(_vm.book, "plot", $$v)
                      },
                      expression: "book.plot"
                    }
                  }),
                  _vm._l(_vm.book.reviews, function(review) {
                    return _c(
                      "div",
                      { key: review.id },
                      [
                        _c("editor", {
                          attrs: { label: "Recensione" },
                          model: {
                            value: review.review,
                            callback: function($$v) {
                              _vm.$set(review, "review", $$v)
                            },
                            expression: "review.review"
                          }
                        }),
                        _c("v-rating", {
                          attrs: {
                            "background-color": "orange lighten-3",
                            color: "orange",
                            "half-increments": ""
                          },
                          model: {
                            value: review.rating,
                            callback: function($$v) {
                              _vm.$set(review, "rating", $$v)
                            },
                            expression: "review.rating"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    [
                      _vm.viewAddReview
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.addReview }
                            },
                            [_vm._v("Aggiungi recensione")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Salva")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }