var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center mt-3" },
    [
      _c(
        "v-flex",
        { attrs: { md4: "" } },
        [
          _c(
            "v-card",
            { staticClass: "pa-4" },
            [
              _c("p", { staticClass: "text-h4 text-center" }, [
                _vm._v("Login")
              ]),
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: { label: "Username", "prepend-icon": "mdi-account" },
                    model: {
                      value: _vm.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Password",
                      "prepend-icon": "mdi-lock",
                      "append-icon": !_vm.showPsw ? "mdi-eye" : "mdi-eye-off",
                      type: !_vm.showPsw ? "password" : "text"
                    },
                    on: {
                      "click:append": function($event) {
                        _vm.showPsw = !_vm.showPsw
                      }
                    },
                    model: {
                      value: _vm.user.password,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { large: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.login("local")
                        }
                      }
                    },
                    [_vm._v("Login")]
                  ),
                  _c("p", { staticClass: "text-body-1 text-center mt-3" }, [
                    _vm._v("Or")
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.login("google")
                        }
                      }
                    },
                    [_vm._v("Google Login")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }