<template>
  <div class="d-flex justify-center mt-3">
    <v-flex md12>
      <v-card class="pa-4">
        <p class="text-h4 text-center">Nuova recensione</p>
        <v-form>
          <div class="text-center">
            <v-switch
              v-model="book.publishedStatus"
              label="Pubblicato"
            ></v-switch>
          </div>
          <v-text-field
            label="Titolo"
            prepend-icon="mdi-account"
            v-model="book.title"
          ></v-text-field>
          <v-combobox
            label="Autore"
            :items="authors"
            item-text="name"
            item-value="id"
            v-model="book.author"
            return-object
            prepend-icon="mdi-account"
          ></v-combobox>
          <v-combobox
            label="Casa Editrice"
            :items="productors"
            item-text="name"
            item-value="id"
            v-model="book.productor"
            return-object
            prepend-icon="mdi-account"
          ></v-combobox>
          <v-combobox
            label="Generi"
            :items="genres"
            item-text="name"
            item-value="id"
            v-model="book.genres"
            prepend-icon="mdi-account"
            return-object
            multiple
          ></v-combobox>
          <v-text-field
            label="Link Amazon"
            prepend-icon="mdi-account"
            v-model="book.amazonLink"
          ></v-text-field>
          <editor v-model="book.citation" label="Citazione"></editor>
          <file-base-64
            accept="image"
            v-model="book.coverImage"
            label="Copertina del libro"
          ></file-base-64>
          <file-base-64
            accept="image"
            v-model="book.homeImage"
            label="Copertina Home"
          ></file-base-64>
          <editor v-model="book.plot" label="Trama"></editor>
          <div v-for="review in book.reviews" :key="review.id">
            <editor v-model="review.review" label="Recensione"></editor>
            <v-rating
              background-color="orange lighten-3"
              color="orange"
              half-increments
              v-model="review.rating"
            ></v-rating>
          </div>
          <div>
            <v-btn color="primary" @click="addReview" v-if="viewAddReview"
              >Aggiungi recensione</v-btn
            >
          </div>
          <div class="text-right">
            <v-btn color="primary" @click="save">Salva</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import editor from "./../editor";
import fileBase64 from "./../fileBase64";
import { mapState } from "vuex";

export default {
  name: "BookEdit",
  props: ["book"],
  components: { editor, fileBase64 },
  computed: {
    ...mapState(["authors", "productors", "genres"]),
    viewAddReview: function() {
      const userId = this.$store.state.auth.user.id;

      return (
        !this.book.reviews ||
        this.book.reviews.filter(review => review.userId == userId).length < 1
      );
    }
  },
  created() {
    this.$store.dispatch("getAuthors");
    this.$store.dispatch("getProductors");
    this.$store.commit("setBtnAction", { visible: false });
  },
  data: () => ({
    publishedStatus: ["Pubblicato", "In Bozza"]
  }),
  methods: {
    addReview() {
      if (!this.book.reviews) {
        this.book.reviews = [];
      }

      const userId = this.$store.state.auth.user.id;

      if (!this.book.userId) {
        this.book.userId = userId;
      }

      if (this.viewAddReview) {
        this.book.reviews.push({
          id: "",
          review: "",
          rating: 0,
          userId: this.$store.state.auth.user.id,
          user: this.$store.state.auth.user
        });
      }
    },
    save() {
      this._.assignInWith(
        {},
        this.book,
        (objValue, srcValue, key, object, source) => {
          if (
            (key === "author" || key === "productor") &&
            this._.isString(source[key])
          ) {
            source[key] = { name: source[key] };
          }

          if (key === "genres") {
            var genres = [];
            source[key].forEach(genre => {
              if (this._.isString(genre)) {
                genres.push({ name: genre });
              } else {
                genres.push(genre);
              }
            });
            source[key] = genres;
          }
        }
      );
      this.$store.dispatch("saveBook", this.book).then(response => {
        this.$router.push({
          name: "BookReview",
          props: { id: response.urlId }
        });
      });
    }
  }
};
</script>
