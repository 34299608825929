var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-2" },
    [
      _c("v-img", {
        attrs: {
          src: _vm.book.homeImage,
          "aspect-ratio": "2",
          contain: "",
          height: "150"
        }
      }),
      _c("v-card-title", [
        _vm._v(_vm._s(_vm.book.title)),
        _c("br"),
        _vm._v("(" + _vm._s(_vm.book.author ? _vm.book.author.name : "") + ")")
      ]),
      _c(
        "v-card-subtitle",
        _vm._l(_vm.book.genres, function(genre, index) {
          return _c("span", { key: genre.id }, [
            _vm._v(" " + _vm._s(genre.name)),
            index < _vm.book.genres.length - 1
              ? _c("span", [_vm._v(", ")])
              : _vm._e()
          ])
        }),
        0
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "primary",
              attrs: {
                to: { name: "BookReview", params: { id: _vm.book.urlId } }
              }
            },
            [_vm._v("Dettaglio")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }