<template>
  <v-app id="inspire">
    <v-app-bar
      absolute
      color="#6A76AB"
      dark
      shrink-on-scroll
      prominent
      src="/img/books-6.jpg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(255,255,255,.4), rgba(25,32,72,.4)"
        ></v-img>
      </template>

      <v-toolbar-title
        class="primary--text"
        :class="{
          'text-h1 pl-16 ml-16': $vuetify.breakpoint.name != 'xs'
        }"
        ><span class="custom-title">Il Mondo del Libro</span></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn class="primary" v-if="!isLogged" :to="{ name: 'Login' }"
        >Login</v-btn
      >

      <v-menu offset-y v-if="isLogged">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar color="brown">
              <img
                v-if="!showInitials"
                @error="showInitials = true"
                :src="user.profilePicture"
              />
              <span v-if="showInitials">M</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="mt-1">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img :src="user.profilePicture" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.username }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item @click="logout">
              <v-list-item-action
                ><v-icon>mdi-logout</v-icon></v-list-item-action
              >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab :to="{ name: 'Home' }"><v-icon>mdi-home</v-icon></v-tab>
        </v-tabs>

        <span v-if="btnAction.visible">
          <Can :I="btnAction.action.params.action" a="book">
            <v-fab-transition>
              <v-btn
                color="pink"
                fab
                dark
                small
                absolute
                bottom
                right
                :to="btnAction.action"
              >
                <v-icon>{{ btnAction.icon }}</v-icon>
              </v-btn>
            </v-fab-transition>
          </Can>
        </span>
      </template>
    </v-app-bar>

    <v-main>
      <v-container style="margin-top: 230px">
        <router-view></router-view>
      </v-container>
    </v-main>

    <footer>
      <cookie-law
        theme="dark-lime"
        buttonText="OK"
        buttonLink="note-legali"
        buttonLinkText="Maggiori Informazioni"
        :buttonLinkNewTab="true"
      >
        <div slot="message">
          Questo sito utilizza i cookie per garantire una navigazione ottimale.
          Proseguendo la navigazione accetti le nostre condizioni d'uso. Fai
          clic su ok per chiudere l'avviso, oppure leggi l'informativa completa.
        </div>
      </cookie-law>
    </footer>

    <v-overlay :value="httpInPending">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import CookieLaw from "vue-cookie-law";

export default {
  components: { CookieLaw },
  computed: {
    ...mapGetters("auth", ["isLogged"]),
    ...mapState("auth", ["user"]),
    ...mapState(["btnAction", "httpInPending"])
  },
  data: () => ({
    showInitials: false
  }),
  metaInfo() {
    return {
      title: "",
      titleTemplate: "%s | ilmondodellibro.eu",
        meta: [{
          name: 'description',
          content: 'Portale dove troverai recensioni dei libri che stiamo leggendo'
        }],
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    logout() {
      this.$store.dispatch("auth/logout");

      if (this.$route.path !== "/") {
        this.$router.push({ name: "Home" });
      }
    }
  },
  mounted() {
    if (
      sessionStorage.getItem("SessionToken") &&
      sessionStorage.getItem("SessionToken") != null
    ) {
      this.login({
        strategy: "jwt",
        accessToken: sessionStorage.getItem("SessionToken")
      });
    }
  }
};
</script>

<style lang="scss">
.custom-title {
  font-family: "Lobster";
}
</style>
