import axios from "axios";

export default {
  getShortUrl: function(urlTOShort) {
    var api = axios.create({
      headers: {
        "Content-Type": "application/json",
        apikey: "a8cc6f019ee74c5cacd7c5a40e79f4d6"
      }
    });

    return api.post("https://api.rebrandly.com/v1/links", {
      destination: urlTOShort,
      domain: {
        fullName: "rebrand.ly"
      }
    });
  }
};
