var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h3", [_vm._v("Cookie tecnici")]),
        _c("p", [
          _vm._v(
            " Vengono utilizzati nella misura strettamente necessaria per garantire un’agevole navigazione da parte degli utenti, consentendo al tempo stesso di memorizzare alcuni dati come le credenziali di accesso. Non vengono utilizzati per altri scopi. I cookie di questa categoria, ed utilizzati nel sito, comprendono: "
          )
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              " cookie di sessione: Utilizzati solo per gli utenti registrati. La registrazione del sito è limitata ai soli fondatori del blog "
            )
          ]),
          _c("li", [
            _vm._v(
              " cookie analytics: utilizzati per raccogliere informazioni sul numero degli utenti e su come visitano il sito. Tutte le informazioni sono raccolte in forma anonima. "
            )
          ])
        ]),
        _c("br"),
        _c("h3", [_vm._v("Cookie utilizzati nel blog")]),
        _c("ul", [
          _c("li", [
            _vm._v(
              " Cookie di sessione: I cookie di sessione si attiveranno solo e soltanto in caso di registrazione e, quindi, di login al blog. Tale servizio è permesso ai soli fondatori del sito: "
            ),
            _c(
              "ul",
              [
                _c("li", [_vm._v("Andreoli Marco")]),
                _c("lI", [_vm._v("Vaccari Stefania")])
              ],
              1
            )
          ]),
          _c("li", [
            _vm._v(
              " Google Analytics: Utilizziamo Analytics per capire come viene utilizzato il sito di 4hse, cercando di migliorare di volta in volta l’esperienza utente. I dati vengono raccolti in forma anonima. Per ulteriori dettagli, potete consultare: "
            ),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: "https://policies.google.com/"
                }
              },
              [_vm._v("Note legali Google")]
            )
          ]),
          _c("li", [
            _vm._v(
              " Amazon Affiliate: I link amazon vengono creati tramite il programma di affiliazione di Amazon stessa. Potete avere ultiriori dettagli tramite il seguente link:"
            ),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    "https://www.amazon.it/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ"
                }
              },
              [_vm._v("Note legali Amazon")]
            )
          ])
        ]),
        _c("br"),
        _c("h3", [_vm._v("Gestione dei cookie")]),
        _c("p", [
          _vm._v(
            " Per l’attivazione o la disattivazione dei cookie, oltre agli strumenti previsti dai diversi browser, invitiamo l’utente che desiderasse approfondire e intervenire sulla configurazione, a visitare il sito:"
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www.youronlinechoices.com/"
              }
            },
            [_vm._v("https://www.youronlinechoices.com/")]
          ),
          _c("br"),
          _vm._v(
            " Per completezza riportiamo, a titolo esemplificativo, i link a cui accedere per attivare o disattivare i cookie di alcuni dei principali browser: "
          )
        ]),
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    "https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=it"
                }
              },
              [_vm._v("Google Chrome")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    "https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it"
                }
              },
              [_vm._v("Mozilla Firefox")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    "https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
                }
              },
              [_vm._v("Internet Explorer")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    "https://support.microsoft.com/en-us/search?query=edge%20clear%20cookies%20and%20cache"
                }
              },
              [_vm._v("Microsoft Edge")]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: "https://help.opera.com/en/latest/web-preferences/"
                }
              },
              [_vm._v("Opera")]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }