import { render, staticRenderFns } from "./bookList.vue?vue&type=template&id=51b29daf&"
import script from "./bookList.vue?vue&type=script&lang=js&"
export * from "./bookList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/marco-andreoli/dev/IlMondoDelLibro-Client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51b29daf')) {
      api.createRecord('51b29daf', component.options)
    } else {
      api.reload('51b29daf', component.options)
    }
    module.hot.accept("./bookList.vue?vue&type=template&id=51b29daf&", function () {
      api.rerender('51b29daf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bookList/bookList.vue"
export default component.exports